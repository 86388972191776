export const GA_EVENTS = {
  OPENING_DASHBOARD_SECTORS: "OPENING_DASHBOARD_SECTORS",
  OPENING_DASHBOARD_INSIGHTS: "OPENING_DASHBOARD_INSIGHTS",
  OPENING_DASHBOARD: "OPENING_DASHBOARD",
  OPENING_DOCUMENT_FA: "OPENING_DOCUMENT_FA",
  OPENING_DOCUMENT_REPORTS: "OPENING_DOCUMENT_REPORTS",
  URL_DOCUMENT_FA: "URL_DOCUMENT_FA",
  URL_DOCUMENT_REPORTS: "URL_DOCUMENT_REPORTS",
  FILE_DOCUMENT_FA: "FILE_DOCUMENT_FA",
  FILE_DOCUMENT_REPORTS: "FILE_DOCUMENT_REPORTS",
  IFRAME_DOCUMENT_FA: "IFRAME_DOCUMENT_FA",
  IFRAME_DOCUMENT_REPORTS: "IFRAME_DOCUMENT_REPORTS",
  OPENING_NEWS: "OPENING_NEWS",
  OPENING_AI: "OPENING_AI",
  OPENING_AINEWS: "OPENING_AI_NEWS",
  OPENING_AISTORIES: "OPENING_AI_STORIES",
  OPENING_HOME: "OPENING_HOME",
  OPENING_NOTIFICATION_UNREAD: "OPENING_NOTIFICATION_UNREAD",
  OPENING_NOTIFICATION_READ: "OPENING_NOTIFICATION_READ",
  OPENING_LOCKED_MODULE: "OPENING_LOCKED_MODULE",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  SEARCH_NEWS: "SEARCH_NEWS",
  SEARCH_DOCUMENT_REPORTS: "SEARCH_DOCUMENT_REPORTS",
  SEARCH_DOCUMENT_FA: "SEARCH_DOCUMENT_FA",
  OPENING_FAVORITES: "OPENING_FAVORITES",
  LOADING_MENU: "LOADING_MENU",
  OPENING_DIGITAL_KIT: "OPENING_DIGITAL_KIT",
  INITIATING_CHATBOT: "INITIATING_CHATBOT",
  OPENING_EXTERNAL_LINK: "OPENING_EXTERNAL_LINK",
};

export const GA_CATEGORIES = {
  DASHBOARD: "DASHBOARD",
  DOCUMENT: "DOCUMENT",
  FILE_DOCUMENT: "FILE_DOCUMENT",
  URL_DOCUMENT: "URL_DOCUMENT",
  IFRAME_DOCUMENT: "IFRAME_DOCUMENT",
  NEWS: "NEWS",
  FAVORITES: "FAVORITES",
  IA: "IA",
  NOTIFICATION: "NOTIFICATION",
  MODULE: "MODULE",
  HOME: "HOME",
  SEARCH: "SEARCH",
  LOGIN: "LOGIN",
  DIGITAL_KIT: "DIGITAL_KIT",
  CHATBOT: "CHATBOT",
  EXTERNAL_LINK: "EXTERNAL_LINK",
};
