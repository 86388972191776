import React, { useState, useContext, useEffect, useRef } from "react";
import { FormContext } from "../formContext";
import {
  SCheckBoxContainer,
  FilterContainer,
  RadioGroup,
  CheckboxGroup,
  DateInputGroup,
  RangeInputGroup,
} from "../styled-components";
import { UpIcon, DownIcon, InfoIcon } from "../icons";
const CheckBox = ({ label, checked, onChange }) => {
  return (
    <SCheckBoxContainer>
      <label>
        {label}
        <input type="checkbox" checked={checked} onChange={onChange} />
        <span className="checkmark"></span>
      </label>
    </SCheckBoxContainer>
  );
};
const IdealistaFilters = ({ filters, disclaimer, onChange, metricName }) => {
  return (
    <FilterContainer>
      <div className="filters-grid">
        {Object.entries(filters).map(([name, config]) => (
          <div key={name} className="filter-item">
            <h4>{name}:</h4>
            <Filter
              name={name}
              config={config}
              onChange={onChange}
              metricName={metricName}
            />
          </div>
        ))}
      </div>
      {disclaimer && <div className="disclaimer">{disclaimer}</div>}
    </FilterContainer>
  );
};

const Filter = ({ name, config, onChange, metricName }) => {
  const { data_type, selection, values } = config;

  switch (data_type) {
    case "LIST":
      return selection === "SINGLE" ? (
        <RadioFilter
          name={name}
          values={values}
          onChange={onChange}
          metricName={metricName}
        />
      ) : (
        <CheckboxFilter
          name={name}
          values={values}
          onChange={onChange}
          metricName={metricName}
        />
      );
    case "DATE":
      return (
        <DateFilter name={name} onChange={onChange} metricName={metricName} />
      );
    case "RANGE":
      return (
        <RangeFilter
          name={name}
          values={values}
          onChange={onChange}
          metricName={metricName}
        />
      );
    default:
      return null;
  }
};

const RadioFilter = ({ name, values, onChange, metricName }) => (
  <RadioGroup>
    {values.map((value) => (
      <label key={value}>
        <input
          type="radio"
          name={`${metricName}-${name}`}
          value={value}
          onChange={(e) => onChange(name, e.target.value, metricName)}
        />
        {value}
      </label>
    ))}
  </RadioGroup>
);

const CheckboxFilter = ({ name, values, onChange, metricName }) => {
  const { formData } = useContext(FormContext);

  const currentValues =
    (formData.filterValues &&
      formData.filterValues[metricName] &&
      formData.filterValues[metricName][name]) ||
    [];
  const [selectedValues, setSelectedValues] = useState(currentValues);

  const handleChange = (value, checked) => {
    let newValues;
    if (checked) {
      newValues = [...selectedValues, value];
    } else {
      newValues = selectedValues.filter((v) => v !== value);
    }
    setSelectedValues(newValues);
    onChange(name, newValues, metricName);
  };

  return (
    <CheckboxGroup>
      {values.map((value) => (
        <SCheckBoxContainer key={value}>
          {value}
          <label>
            <input
              type="checkbox"
              name={`${metricName}-${name}`}
              value={value}
              checked={selectedValues.includes(value)}
              onChange={(e) => handleChange(value, e.target.checked)}
            />
            <span className="checkmark"></span>
          </label>
        </SCheckBoxContainer>
      ))}
    </CheckboxGroup>
  );
};

const DateFilter = ({ name, onChange, metricName }) => (
  <DateInputGroup>
    <div className="date-input">
      <input
        type="date"
        name={`${metricName}-${name}`}
        onChange={(e) => onChange(name, e.target.value, metricName)}
      />
    </div>
  </DateInputGroup>
);

const RangeFilter = ({ name, values, onChange, metricName }) => {
  const [min, max] = values;
  const { formData } = useContext(FormContext);
  const sliderRef = useRef(null);

  const [currentValues, setCurrentValues] = useState(() => {
    const savedValues =
      formData.filterValues &&
      formData.filterValues[metricName] &&
      formData.filterValues[metricName][name];
    return savedValues || [min, max];
  });

  const handleInputChange = (value, index) => {
    const numValue = parseFloat(value);
    if (isNaN(numValue)) return;

    let newValues;
    if (index === 0) {
      newValues = [
        Math.min(Math.max(numValue, min), currentValues[1]),
        currentValues[1],
      ];
    } else {
      newValues = [
        currentValues[0],
        Math.max(Math.min(numValue, max), currentValues[0]),
      ];
    }

    setCurrentValues(newValues);
    onChange(name, newValues, metricName);
  };

  const getPercentage = (value) => {
    return ((value - min) / (max - min)) * 100;
  };

  const getValueFromPosition = (clientX) => {
    const rect = sliderRef.current.getBoundingClientRect();
    const percentage = Math.max(
      0,
      Math.min(1, (clientX - rect.left) / rect.width)
    );
    return min + (max - min) * percentage;
  };

  const handleSliderMove = (clientX, index) => {
    const newValue = getValueFromPosition(clientX);

    let newValues;
    if (index === 0) {
      newValues = [
        Math.min(Math.max(newValue, min), currentValues[1]),
        currentValues[1],
      ];
    } else {
      newValues = [
        currentValues[0],
        Math.max(Math.min(newValue, max), currentValues[0]),
      ];
    }

    setCurrentValues(newValues);
    onChange(name, newValues, metricName);
  };

  const handleMouseDown = (e, index) => {
    e.preventDefault();
    e.stopPropagation();

    const handleMouseMove = (moveEvent) => {
      moveEvent.preventDefault();
      handleSliderMove(moveEvent.clientX, index);
    };

    const handleMouseUp = () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  // Handle touch events
  const handleTouchStart = (e, index) => {
    e.preventDefault();
    e.stopPropagation();

    const handleTouchMove = (moveEvent) => {
      moveEvent.preventDefault();
      const touch = moveEvent.touches[0];
      handleSliderMove(touch.clientX, index);
    };

    const handleTouchEnd = () => {
      document.removeEventListener("touchmove", handleTouchMove);
      document.removeEventListener("touchend", handleTouchEnd);
    };

    document.addEventListener("touchmove", handleTouchMove, { passive: false });
    document.addEventListener("touchend", handleTouchEnd);
  };

  return (
    <RangeInputGroup>
      <div className="slider-container" ref={sliderRef}>
        <div className="slider-track" />
        <div
          className="slider-fill"
          style={{
            left: `${getPercentage(currentValues[0])}%`,
            width: `${
              getPercentage(currentValues[1]) - getPercentage(currentValues[0])
            }%`,
          }}
        />
        <div
          className="slider-handle min"
          style={{ left: `${getPercentage(currentValues[0])}%` }}
          onMouseDown={(e) => handleMouseDown(e, 0)}
          onTouchStart={(e) => handleTouchStart(e, 0)}
        />
        <div
          className="slider-handle max"
          style={{ left: `${getPercentage(currentValues[1])}%` }}
          onMouseDown={(e) => handleMouseDown(e, 1)}
          onTouchStart={(e) => handleTouchStart(e, 1)}
        />
      </div>

      <div className="range-inputs">
        <input
          type="number"
          min={min}
          disabled
          max={currentValues[1]}
          value={currentValues[0]}
          onChange={(e) => handleInputChange(e.target.value, 0)}
        />
        <input
          type="number"
          disabled
          min={currentValues[0]}
          max={max}
          value={currentValues[1]}
          onChange={(e) => handleInputChange(e.target.value, 1)}
        />
      </div>
    </RangeInputGroup>
  );
};

const KpiGroup = ({ group, metrics, setShowModalInfo }) => {
  const { formData, setFormData } = useContext(FormContext);
  const [isOpen, setIsOpen] = useState(false);
  const handleMetricSelection = (metric, checked) => {
    setFormData((prev) => ({
      ...prev,
      selectedGroups: {
        ...prev.selectedGroups,
        [group]: {
          ...(prev.selectedGroups[group] || {}),
          [metric]: {
            selected: checked,
            filters: checked
              ? prev.selectedGroups[group] &&
                prev.selectedGroups[group][metric] &&
                prev.selectedGroups[group][metric].filters
              : null,
          },
        },
      },
    }));
  };

  const handleFilterChange = (metric, filterName, value) => {
    setFormData((prev) => ({
      ...prev,
      selectedGroups: {
        ...prev.selectedGroups,
        [group]: {
          ...(prev.selectedGroups[group] || {}),
          [metric]: {
            ...((prev.selectedGroups[group] &&
              prev.selectedGroups[group][metric]) ||
              {}),
            selected: true,
            filters: {
              ...((prev.selectedGroups[group] &&
                prev.selectedGroups[group][metric] &&
                prev.selectedGroups[group][metric].filters) ||
                {}),
              [filterName]: value,
            },
          },
        },
      },
    }));
  };

  const isMetricSelected = (metric) => {
    return (
      (formData.selectedGroups &&
        formData.selectedGroups[group] &&
        formData.selectedGroups[group][metric] &&
        formData.selectedGroups[group][metric].selected) ||
      false
    );
  };

  return (
    <div className="data-group">
      <div className="group-header" onClick={() => setIsOpen(!isOpen)}>
        <span>{group}</span>{" "}
        {isOpen ? (
          <UpIcon width={15} height={15} />
        ) : (
          <DownIcon width={15} height={15} />
        )}
      </div>
      <div
        className="group-content"
        style={{ display: isOpen ? "flex" : "none" }}
      >
        {Object.entries(metrics).map(([metric, metricData]) => (
          <div key={metric} className="group-item">
            <div className="metric-row">
              <div className="metric-label">
                <CheckBox
                  label={metric}
                  checked={isMetricSelected(metric)}
                  onChange={(e) =>
                    handleMetricSelection(metric, e.target.checked)
                  }
                />
              </div>
              {metricData.info && (
                <InfoIcon
                  className="info-icon"
                  onClick={() =>
                    setShowModalInfo({ title: metric, info: metricData.info })
                  }
                />
              )}
            </div>
            {metricData.filters && isMetricSelected(metric) && (
              <IdealistaFilters
                filters={metricData.filters}
                disclaimer={metricData.disclaimer}
                onChange={(filterName, value) =>
                  handleFilterChange(metric, filterName, value)
                }
                metricName={metric}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
export {
  KpiGroup,
  IdealistaFilters,
  Filter,
  RadioFilter,
  CheckboxFilter,
  DateFilter,
  RangeFilter,
};
