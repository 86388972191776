import React, { createContext, useState } from "react";

export const FormContext = createContext({
  formData: {
    selectedGroups: {},
    selectedMetrics: {},
    filterValues: {},
  },
  setFormData: () => {},
});

export const FormProvider = ({ children }) => {
  const [formData, setFormData] = useState({
    selectedGroups: {},
    timeValue: null,
  });

  return (
    <FormContext.Provider value={{ formData, setFormData }}>
      {children}
    </FormContext.Provider>
  );
};

export default FormProvider;
