import { useMutation, useQuery } from "react-query";
import axios from "axios";
import { API_URLS } from "constants/apiUrl";

export const useKpiData = () => {
  return useQuery({
    queryKey: ["kpi-data"],
    queryFn: async () => {
      const response = await axios.get("/data-market");
      return response.data;
    },
    keepPreviousData: true,
    placeholderData: (prev) => prev,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });
};

export const useGetMyProfile = () => {
  return useQuery({
    queryKey: ["my-profile"],
    queryFn: async () => {
      const response = await axios.get(API_URLS.GET_MY_PROFILE);
      return response.data;
    },
    keepPreviousData: true,
    placeholderData: (prev) => prev,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });
};
export const useCreateKpiRequest = () => {
  return useMutation({
    mutationFn: async (data) => {
      const response = await axios.post("/data-market", data);
      return response.data;
    },
  });
};
