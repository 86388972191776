import styled from "styled-components";
import { STYLES_CONSTANTS } from "styles/constants/constants";

const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  align-items: center;
  right: 0;
  padding: 10px;
  z-index: 10;
  background-color: ${STYLES_CONSTANTS.COLORS.PRIMARY_GREEN};
  .logo-container {
    display: flex;
    align-items: center;
    gap: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    gap: 10px;

    font-family: "Financier", sans-serif !important;
    font-size: 24px;
  }
  h1 {
    font-family: "Financier", sans-serif !important;
    font-size: 35px;
    color: white;
    margin: 0;
  }
  .help-container {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
  }
  h2 {
    font-family: "Financier", sans-serif !important;
    font-size: 18px;
    color: ${STYLES_CONSTANTS.COLORS.ACCENT_GREEN};
    margin: 0;
  }
  .divider {
    width: 1px;
    height: 35px;

    background-color: ${STYLES_CONSTANTS.COLORS.ACCENT_GREEN};
    margin: 0 10px;
    margin-left: -3px;
  }
  svg {
    fill: white;
    stroke: white;
    width: 100px;
    height: 18px;
  }
`;
const SearchBox = styled.div`
  position: absolute;
  top: 90px;
  left: 25px;
  z-index: 1;
  width: 550px;
  div.mapboxgl-ctrl {
    width: 100% !important;
  }
  * {
    font-family: "Calibre-Light", sans-serif !important;
    font-size: 18px !important;
  }
`;
const ContextMenu = styled.div`
  position: absolute;

  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  background: #cad1d3;
  min-width: 250px;
  z-index: 2;

  h3 {
    padding: 14px 16px;
    margin: 0;
    color: #012a2d;
    font-size: 14px;
    border-bottom: 1px solid #eee;
  }

  .menu-item {
    padding: 8px 16px;
    display: flex;
    align-items: center;
    background: white;
    justify-content: space-between;
    div {
      display: flex;
      align-content: center;
      justify-content: center;
      align-items: center;
      gap: 10px;
    }
    gap: 8px;
    cursor: pointer;
    &:hover {
      background-color: #f5f5f5;
    }
  }
`;

const DrawingInstructions = styled.div`
  position: absolute;

  min-width: 250px;
  z-index: 2;
  background: white;
  h3 {
    background: #cad1d3;
    padding: 14px 16px;
    margin: 0;
    color: #012a2d;
    font-size: 14px;
    border-bottom: 1px solid #eee;
  }
  p {
    padding: 16px;
  }
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);

  z-index: 2;
  top: 200px;
  right: -5%;
  transform: translate(-50%, -50%);
  text-align: center;

  .buttons {
    padding: 16px;
    padding-bottom: 10px;
    display: flex;
    gap: 8px;
    margin-top: 12px;
    justify-content: space-around;
  }

  button {
    padding: 8px 16px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    width: 100%;
    &.cancel {
      background: #e0e0e0;
    }

    &.confirm {
      background: ${STYLES_CONSTANTS.COLORS.PRIMARY_GREEN};
      color: white;
    }
  }
`;

const TimeSliderDialog = styled.div`
  position: absolute;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  background: white;
  min-width: 250px;
  z-index: 2;
  border-radius: 4px;

  h3 {
    padding: 14px 16px;
    margin: 0;
    color: #012a2d;
    font-size: 14px;
    background: #cad1d3;
    border-bottom: 1px solid #eee;
  }

  .slider-container {
    padding: 16px;

    input[type="range"] {
      -webkit-appearance: none;
      width: 100%;
      height: 2px;
      background: #e0e0e0;
      margin: 20px 0;
      position: relative;
      step: 5;

      &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 8px;
        top: -42%;
        transform: translateY(-50%);
        background: repeating-linear-gradient(
          to right,
          #e0e0e0,
          #e0e0e0 1px,
          transparent 1px,
          transparent calc(100% / 12)
        );
        pointer-events: none;
      }

      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        width: 20px;
        margin-top: -10px;
        height: 20px;
        top: -42%;
        border-radius: 50%;
        background: ${STYLES_CONSTANTS.COLORS.PRIMARY_GREEN};
        cursor: pointer;
        border: 3px solid white;
        box-shadow: 0 0 0 1px #ccc;
        position: relative;
        z-index: 1;
      }
      &::-moz-range-thumb {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: ${STYLES_CONSTANTS.COLORS.PRIMARY_GREEN};
        cursor: pointer;
        border: 3px solid white;
        box-shadow: 0 0 0 1px #ccc;
      }

      &::-ms-thumb {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: ${STYLES_CONSTANTS.COLORS.PRIMARY_GREEN};
        cursor: pointer;
        border: 3px solid white;
        box-shadow: 0 0 0 1px #ccc;
      }

      &::-webkit-slider-runnable-track {
        height: 2px;
        background: #e0e0e0;
      }

      &::-moz-range-track {
        height: 2px;
        background: #e0e0e0;
      }

      &::-ms-track {
        height: 2px;
        background: #e0e0e0;
      }
    }
  }

  .time-display {
    text-align: center;
    margin: 5px 0;
    color: #012a2d;
    font-size: 14px;
    position: relative;

    &::before {
      content: "0 min";
      position: absolute;
      left: 0;
      font-size: 12px;
      color: #999;
    }

    &::after {
      content: "60 min";
      position: absolute;
      right: 0;
      font-size: 12px;
      color: #999;
    }
  }

  .buttons {
    padding: 16px;
    display: flex;
    gap: 8px;
    justify-content: space-between;
  }

  button {
    padding: 8px 16px;

    border: none;
    cursor: pointer;
    width: 100%;

    &.cancel {
      background: #e0e0e0;
    }

    &.confirm {
      background: ${STYLES_CONSTANTS.COLORS.PRIMARY_GREEN};
      color: white;
    }
  }
    .admin-options {
        padding: 0px 20px;
  display: flex;
  gap: 15px;
  margin: 10px 0;
  flex-direction: column;
  label {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
  }

  input[type="radio"] {
    appearance: none;
    width: 18px;
    height: 18px;
    border: 2px solid #ccc;
    border-radius: 50%;
    margin: 0;

    &:checked {
      border: 5px solid ${STYLES_CONSTANTS.COLORS.PRIMARY_GREEN};
    }

    }
`;
const SCheckBoxContainer = styled.div`
  display: block;
  position: relative;
  padding-left: 35px;

  cursor: pointer;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  label {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
  }
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
  }

  /* On mouse-over, add a grey background color */
  :hover input ~ .checkmark {
    background-color: #ccc;
  }

  /* When the checkbox is checked, add a blue background */
  input:checked ~ .checkmark {
    background-color: ${STYLES_CONSTANTS.COLORS.PRIMARY_GREEN};
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .checkmark:after {
    left: 6px;
    top: 1px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

const DataDrawer = styled.div`
  position: absolute;
  top: 0;
  right: ${(props) => (props.isOpen ? "0" : "-500px")};
  width: 500px;
  height: 100%;
  background: white;
  box-shadow: -2px 0 4px rgba(0, 0, 0, 0.1);
  transition: right 0.3s ease;
  z-index: 11;
  display: flex;
  flex-direction: column;

  .drawer-header {
    position: sticky;
    top: 0;
    padding: 20px;
    background: #cad1d3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1;

    h2 {
      margin: 0;
      font-size: 16px;
      color: #012a2d;
    }

    .close-button {
      background: none;
      border: none;
      cursor: pointer;
      padding: 5px;
    }
  }

  .drawer-content {
    flex: 1;
    overflow-y: auto;
    padding-bottom: 80px;

    .data-group {
      margin-bottom: 5px;

      .group-header {
        padding: 10px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        background: #f0f0f0;
      }

      .group-content {
        padding: 10px 45px;
        background: white;
        display: flex;
        flex-direction: column;
        gap: 10px;

        .group-item {
          .metric-row {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 10px;
          }

          .metric-label {
            display: flex;
            align-items: center;
            gap: 10px;

            .info-icon {
              color: #999;
              cursor: help;
            }
          }
        }
      }
    }
  }

  .drawer-footer {
    position: sticky;
    bottom: 0;
    width: 100%;
    padding: 20px;
    display: flex;
    gap: 10px;
    background: white;
    border-top: 1px solid #eee;
    z-index: 1;

    button {
      padding: 10px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      flex: 1;

      &.cancel {
        background: #e0e0e0;
      }

      &.confirm {
        background: ${(props) =>
          props.disabled ? "#cccccc" : STYLES_CONSTANTS.COLORS.PRIMARY_GREEN};
        color: white;
      }

      &:disabled {
        background: #cccccc;
        cursor: not-allowed;
      }
    }
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
  display: ${(props) => (props.isOpen ? "block" : "none")};
`;
const ModalInfo = styled.div`
  position: absolute;

  background: white;
  z-index: 10;
  display: ${(props) => (props.isOpen ? "block" : "none")};

  position: absolute;
  top: 20%;
  left: 20%;
  right: 0;
  bottom: 0;
  width: 400px;
  height: 250px;
  background: white;
  padding: 20px;
  flex-direction: column;
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  h3 {
    font-family: "Calibre-Medium", sans-serif !important;
  }
  strong {
    font-family: "Calibre-Medium", sans-serif !important;
  }
`;
const FilterContainer = styled.div`
  border-top: 1px solid #eee;

  .filters-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin-bottom: 15px;
  }

  .filter-item {
    min-width: 0;
  }

  h4 {
    color: #012a2d;
    font-size: 14px;
    margin: 10px 0;
  }

  .disclaimer {
    font-size: 12px;
    color: #dc3545;
    font-style: italic;
    margin: 10px 0;
    grid-column: 1 / -1;
  }
`;

const RadioGroup = styled.div`
  display: flex;
  gap: 15px;
  margin: 10px 0;
  flex-direction: column;
  label {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
  }

  input[type="radio"] {
    appearance: none;
    width: 18px;
    height: 18px;
    border: 2px solid #ccc;
    border-radius: 50%;
    margin: 0;

    &:checked {
      border: 5px solid ${STYLES_CONSTANTS.COLORS.PRIMARY_GREEN};
    }
  }
`;

const CheckboxGroup = styled.div`
  display: flex;
  gap: 15px;
  margin: 10px 0;
  flex-direction: column;
`;

const DateInputGroup = styled.div`
  display: flex;
  gap: 15px;
  margin: 10px 0;
  flex-direction: column;
  .date-input {
    position: relative;
    flex: 1;

    input {
      width: 100%;
      padding: 8px;
      padding-right: 30px;
      border: 1px solid #ccc;
      border-radius: 4px;
    }

    svg {
      position: absolute;
      right: 8px;
      top: 50%;
      transform: translateY(-50%);
      color: #666;
    }
  }
`;
const RangeInputGroup = styled.div`
  position: relative;
  width: 100%;
  padding: 15px 0;

  .slider-container {
    position: relative;
    width: 100%;
    height: 40px;
    padding: 15px 0;
    touch-action: none;
  }

  .slider-track {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    height: 4px;
    background: #ddd;
    border-radius: 2px;
  }

  .slider-fill {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 4px;
    background: ${STYLES_CONSTANTS.COLORS.PRIMARY_GREEN};
    border-radius: 2px;
    pointer-events: none;
  }

  .slider-handle {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 18px;
    height: 18px;
    background: white;
    border: 2px solid ${STYLES_CONSTANTS.COLORS.PRIMARY_GREEN};
    border-radius: 50%;
    cursor: pointer;
    z-index: 2;
    touch-action: none;
    user-select: none;
  }

  .range-values {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;

    span {
      font-size: 14px;
      color: #666;
    }
  }

  .range-inputs {
    display: flex;
    justify-content: space-between;
    gap: 10px;

    input {
      width: 80px;
      padding: 4px 8px;
      border: 1px solid #ddd;
      border-radius: 4px;
      font-size: 14px;

      &:focus {
        outline: none;
        border-color: ${STYLES_CONSTANTS.COLORS.PRIMARY_GREEN};
      }
    }
  }
`;
const SuccessModal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  z-index: 1000;
  width: 400px;

  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    color: #666;
  }

  .success-icon {
  }

  h2 {
    margin: 0 0 10px;
    color: #333;
  }

  p {
    margin: 0;
    color: #666;
    line-height: 1.5;
  }
`;
const TrashButton = styled.button`
  position: absolute;
  background: white;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  padding: 10px;
  z-index: 2;
  bottom: 0;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  right: 0;
`;
export {
  PageHeader,
  SearchBox,
  ContextMenu,
  DrawingInstructions,
  TimeSliderDialog,
  SCheckBoxContainer,
  DataDrawer,
  Overlay,
  ModalInfo,
  TrashButton,
  SuccessModal,
  FilterContainer,
  RadioGroup,
  CheckboxGroup,
  DateInputGroup,
  RangeInputGroup,
};
