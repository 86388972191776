import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import { STYLES_CONSTANTS } from 'styles/constants/constants';

const rotateCircles = keyframes`
  0% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const SOverlay = styled.div`
    position: ${({ fullscreen }) => fullscreen ? 'fixed' : 'relative'};
    top: 0;
    left: 0;
    z-index: ${({ fullscreen }) => fullscreen ? STYLES_CONSTANTS.ZINDEX.ZINDEX_FULLSCREEN : STYLES_CONSTANTS.ZINDEX.ZINDEX_MODAL_SPINNER};
    height: ${({ fullscreen }) => fullscreen ? '100vh' : '100%'};
    width: ${({ fullscreen }) => fullscreen ? '100vw' : '100%'};
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${STYLES_CONSTANTS.COLORS.WHITE_50};
`;

const SCircle = styled.div`
     border: 2px solid transparent;
     animation: ${rotateCircles} linear infinite;
     border-radius: 50%;
     display: flex;
     justify-content: center;
     align-items: center;
`;

const SCircle1 = styled(SCircle)`
    position: relative;
    top: -5%;
    animation-duration: 2s;
    width: 60px;
    height: 60px;
    border-bottom-color: ${STYLES_CONSTANTS.COLORS.DATA_BLUE};
    border-left-color: ${STYLES_CONSTANTS.COLORS.DATA_BLUE};
`;

const SCircle2 = styled(SCircle)`
    animation-duration: 4s;
    width: 50px;
    height: 50px;
    border-top-color: ${STYLES_CONSTANTS.COLORS.DARK_GREEN_2};
    border-left-color: ${STYLES_CONSTANTS.COLORS.DARK_GREEN_2};
`;

const SCircle3 = styled(SCircle)`
    animation-duration: 5s;
    width: 40px;
    height: 40px;
    border-top-color: ${STYLES_CONSTANTS.COLORS.DARK_SKY_GREEN};
    border-right-color: ${STYLES_CONSTANTS.COLORS.DARK_SKY_GREEN};
`;

// eslint-disable-next-line react/prop-types
const SpinnerIcon = ({ fullscreen, className }) => {
    return (
        <SOverlay fullscreen={fullscreen} className={className}>
            <SCircle1>
                <SCircle2>
                    <SCircle3 />
                </SCircle2>
            </SCircle1>
        </SOverlay>
    );
};

SpinnerIcon.propTypes = {
    fullscreen: PropTypes.bool.isRequired,
};


export const Spinner = ({ isOverlay, isLoading, children, fullscreen, className }) => {
    if (isOverlay && isLoading) {
        return (
            <Fragment>
                <SpinnerIcon fullscreen className={className} />
                {children}
            </Fragment>
        );
    }

    return (
        isLoading ? (
            <SpinnerIcon fullscreen={fullscreen} className={className} />
        ) : children
    );
};

Spinner.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
    fullscreen: PropTypes.bool,
    isOverlay: PropTypes.bool,
    // eslint-disable-next-line react/require-default-props
    className: PropTypes.string,
};

Spinner.defaultProps = {
    fullscreen: false,
    isOverlay: false,
};
