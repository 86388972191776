import axios from "axios";
import { TYPES } from "actionTypes/users";
import { API_URLS } from "constants/apiUrl";
export const downloadUsers =
  ({ groups }) =>
  async (dispatch) => {
    // dispatch({
    //   type: TYPES.DOWNLOAD_USERS.PENDING,
    // });
    try {
      const response = await axios.post(
        `${API_URLS.GET_USERS}/excel`,
        {
          groups,
        },
        {
          responseType: "blob",
        }
      );
      // dispatch({
      //   type: TYPES.DOWNLOAD_USERS.FULFILLED,
      //   payload: response.data,
      // });
      return response;
    } catch (error) {
      // dispatch({
      //   type: TYPES.DOWNLOAD_USERS.REJECTED,
      //   payload: error,
      // });
      throw error;
    }
  };
export const getUsers = (params) => {
  return async (dispatch) => {
    dispatch({
      type: TYPES.GET_USERS.PENDING,
    });
    try {
      const res = await axios.get(API_URLS.GET_USERS, { params });
      dispatch({
        type: TYPES.GET_USERS.FULFILLED,
        payload: res.data,
      });
    } catch (e) {
      dispatch({
        type: TYPES.GET_USERS.REJECTED,
      });
      throw e;
    }
  };
};
export const clearUsers = (params) => {
  return async (dispatch) => {
    dispatch({
      type: TYPES.GET_USERS.PENDING,
    });
    try {
      // const res = await axios.get(API_URLS.GET_USERS, { params });
      dispatch({
        type: TYPES.GET_USERS.FULFILLED,
        payload: { results: [], count: 0, page: 0, limit: 20 },
      });
    } catch (e) {
      dispatch({
        type: TYPES.GET_USERS.REJECTED,
      });
      throw e;
    }
  };
};
export const getAppUsers = (params) => {
  return async (dispatch) => {
    dispatch({
      type: TYPES.GET_APP_USERS.PENDING,
    });
    try {
      const res = await axios.get(API_URLS.GET_USERS + "/app", { params });
      dispatch({
        type: TYPES.GET_APP_USERS.FULFILLED,
        payload: res.data,
      });
    } catch (e) {
      dispatch({
        type: TYPES.GET_APP_USERS.REJECTED,
      });
      throw e;
    }
  };
};
export const getContactUsers = (params) => {
  return async (dispatch) => {
    dispatch({
      type: TYPES.GET_USERS_SEARCH.PENDING,
    });
    try {
      const res = await axios.get(API_URLS.GET_USERS + "/search", { params });
      dispatch({
        type: TYPES.GET_USERS_SEARCH.FULFILLED,
        payload: res.data,
      });
    } catch (e) {
      dispatch({
        type: TYPES.GET_USERS_SEARCH.REJECTED,
      });
      throw e;
    }
  };
};
export const getSingleUser = (userId) => {
  return async (dispatch) => {
    dispatch({
      type: TYPES.GET_SINGLE_USER.PENDING,
    });
    try {
      const res = await axios.get(`${API_URLS.GET_SINGLE_USER}/${userId}`);
      dispatch({
        type: TYPES.GET_SINGLE_USER.FULFILLED,
        payload: res.data,
      });
    } catch (e) {
      dispatch({
        type: TYPES.GET_SINGLE_USER.REJECTED,
      });
    }
  };
};
export const createUser = (data) => {
  return async (dispatch) => {
    dispatch({
      type: TYPES.UPDATE_USER.PENDING,
    });
    try {
      const res = await axios.post(`${API_URLS.GET_SINGLE_USER}`, data);
      dispatch({
        type: TYPES.UPDATE_USER.FULFILLED,
        payload: res.data,
      });
      return res.data;
    } catch (e) {
      dispatch({
        type: TYPES.UPDATE_USER.REJECTED,
      });
      return e;
    }
  };
};
export const updateUserInfo = (userId, data) => {
  return async (dispatch) => {
    dispatch({
      type: TYPES.UPDATE_USER.PENDING,
    });
    try {
      const res = await axios.patch(
        `${API_URLS.GET_SINGLE_USER}/${userId}`,
        data
      );
      dispatch({
        type: TYPES.UPDATE_USER.FULFILLED,
        payload: res.data,
      });
    } catch (e) {
      dispatch({
        type: TYPES.UPDATE_USER.REJECTED,
      });
      return e;
    }
  };
};

export const addContact = (userId, contacts) => {
  return async (dispatch) => {
    dispatch({
      type: TYPES.ADD_CONTACT.PENDING,
    });
    try {
      const res = await axios.put(`${API_URLS.ADD_CONTACT}/${userId}`, {
        contacts,
      });
      dispatch({
        type: TYPES.ADD_CONTACT.FULFILLED,
        payload: res.data,
      });
    } catch (e) {
      dispatch({
        type: TYPES.ADD_CONTACT.REJECTED,
      });
    }
  };
};

export const getCBREUsers = (name) => {
  return async (dispatch) => {
    dispatch({
      type: TYPES.GET_CBRE_USERS.PENDING,
    });
    try {
      const res = await axios.get(API_URLS.GET_CBRE_USERS, {
        params: {
          cbre: true,
          name,
        },
      });
      dispatch({
        type: TYPES.GET_CBRE_USERS.FULFILLED,
        cbreUsers: res.data.results,
      });
    } catch (e) {
      dispatch({
        type: TYPES.GET_CBRE_USERS.REJECTED,
      });
    }
  };
};

export const getMyProfile = () => {
  return async (dispatch) => {
    dispatch({
      type: TYPES.GET_MY_PROFILE.PENDING,
    });
    try {
      const res = await axios.get(API_URLS.GET_MY_PROFILE);
      dispatch({
        type: TYPES.GET_MY_PROFILE.FULFILLED,
        payload: res.data,
      });
      try {
        localStorage.setItem("user_claims", JSON.stringify(res.data.claims));
      } catch (error) {
        console.error("Error parsing user_claims:", error);
      }
      return res.data;
    } catch (e) {
      dispatch({
        type: TYPES.GET_MY_PROFILE.REJECTED,
      });
    }
  };
};

export const sendShareMail = (data) => {
  return async (dispatch) => {
    dispatch({
      type: TYPES.SEND_SUPPORT_MAIL.PENDING,
    });
    try {
      await axios.post(API_URLS.POST_SHARE_MAIL, {
        ...data,
      });
      dispatch({
        type: TYPES.SEND_SUPPORT_MAIL.FULFILLED,
      });
    } catch (error) {
      dispatch({
        type: TYPES.SEND_SUPPORT_MAIL.REJECTED,
      });
      throw error;
    }
  };
};

export const sendSupportMail = (data) => {
  return async (dispatch) => {
    dispatch({
      type: TYPES.SEND_SUPPORT_MAIL.PENDING,
    });
    try {
      await axios.post(API_URLS.POST_SUPPORT_MAIL, {
        ...data,
      });
      dispatch({
        type: TYPES.SEND_SUPPORT_MAIL.FULFILLED,
      });
    } catch (error) {
      dispatch({
        type: TYPES.SEND_SUPPORT_MAIL.REJECTED,
      });
      throw error;
    }
  };
};

export const deleteUser = (userId) => {
  return async (dispatch) => {
    dispatch({
      type: TYPES.DELETE_USER.PENDING,
    });
    try {
      await axios.delete(`${API_URLS.DELETE_USER}/${userId}`);
      dispatch({
        type: TYPES.DELETE_USER.FULFILLED,
      });
    } catch (e) {
      dispatch({
        type: TYPES.DELETE_USER.REJECTED,
      });
      throw e;
    }
  };
};

export const resetOktaPassword = (email) => {
  return async (dispatch) => {
    dispatch({
      type: TYPES.GET_SINGLE_USER.PENDING,
    });
    try {
      await axios.post(`${API_URLS.DELETE_USER}/password-reset`, {
        email,
      });
      dispatch({
        type: TYPES.GET_SINGLE_USER.FULFILLED,
      });
    } catch (e) {
      dispatch({
        type: TYPES.GET_SINGLE_USER.REJECTED,
      });
      throw e;
    }
  };
};

export const setUserAsSbu = (id) => async (dispatch) => {
  dispatch({
    type: TYPES.SET_USER_AS_SBU.PENDING,
  });
  try {
    const response = await axios.patch(`${API_URLS.GET_USERS}/${id}/set-sbu`);
    dispatch({
      type: TYPES.SET_USER_AS_SBU.FULFILLED,
    });
    return response;
  } catch (error) {
    dispatch({
      type: TYPES.SET_USER_AS_SBU.REJECTED,
      payload: error,
    });
    throw error;
  }
};

export const addSbuGroup = (id, groups) => async (dispatch) => {
  dispatch({
    type: TYPES.ADD_SBU_GROUP.PENDING,
  });
  try {
    const response = await axios.patch(
      `${API_URLS.GET_USERS}/${id}/assign-groups`,
      { groups }
    );
    dispatch({
      type: TYPES.ADD_SBU_GROUP.FULFILLED,
    });
    return response;
  } catch (error) {
    dispatch({
      type: TYPES.ADD_SBU_GROUP.REJECTED,
      payload: error,
    });
    throw error;
  }
};
