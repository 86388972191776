import React from "react";

export function WalkingIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={48}
      height={48}
      viewBox="0 0 48 48"
      {...props}
    >
      <g fill="currentColor" fillRule="evenodd" clipRule="evenodd">
        <path d="M18.574 31.453a1 1 0 0 1 .948.245l3.249 3.116a1 1 0 0 1 .266 1.005l-.709 2.394a3 3 0 0 1-1.026 1.51l-4.643 3.636a3 3 0 1 1-3.7-4.723l3.889-3.046l1.023-3.455a1 1 0 0 1 .703-.682m.749 2.824l-.649 2.187a1 1 0 0 1-.342.503l-4.14 3.244a1 1 0 0 0 1.233 1.574l4.643-3.637a1 1 0 0 0 .343-.503l.536-1.81zM28 12a3 3 0 1 0 0-6a3 3 0 0 0 0 6m0 2a5 5 0 1 0 0-10a5 5 0 0 0 0 10"></path>
        <path d="M21.737 14.364c1.428-.53 2.962-.5 4.339.213c1.31.677 2.197 1.823 2.755 3.021q.313.672.583 1.259c.44.954.8 1.737 1.145 2.42c.486.967.842 1.534 1.156 1.9c.27.313.501.472.799.588c.35.138.923.263 1.942.294a2.794 2.794 0 0 1-.167 5.586c-1.357-.041-2.634-.215-3.809-.674c-1.228-.48-2.197-1.213-3.005-2.155a9 9 0 0 1-.28-.342l-.403 2.104l4.367 4.309c.279.274.496.604.639.968l2.484 6.33a2.794 2.794 0 1 1-5.202 2.041l-2.268-5.777l-7.859-7.755a2.8 2.8 0 0 1-.814-2.303l.325-2.87c-.892 1.153-1.642 2.54-2.166 4.18a2.794 2.794 0 1 1-5.324-1.699c1.768-5.538 5.767-9.709 10.73-11.625l.006-.003zm.7 1.874l-.016.006c-4.382 1.693-7.954 5.391-9.541 10.366a.794.794 0 1 0 1.513.483c.994-3.113 2.725-5.468 4.76-7.13a1 1 0 0 1 1.626.886l-.652 5.767a.8.8 0 0 0 .231.654l8.01 7.904q.151.149.229.346l2.345 5.976a.794.794 0 1 0 1.478-.58l-2.484-6.33a.8.8 0 0 0-.181-.276l-4.748-4.684a1 1 0 0 1-.28-.9l.981-5.125a1 1 0 0 1 1.868-.277c.45.857.9 1.59 1.416 2.19c.616.717 1.321 1.244 2.216 1.594c.88.344 1.905.5 3.14.537a.794.794 0 1 0 .048-1.587c-1.14-.034-1.964-.177-2.61-.43c-.63-.246-1.126-.61-1.588-1.149c-.476-.553-.918-1.294-1.425-2.302a83 83 0 0 1-1.17-2.47l-.005-.012q-.269-.587-.58-1.253c-.43-.922-1.05-1.67-1.86-2.089c-.828-.428-1.77-.468-2.722-.115"></path>
      </g>
    </svg>
  );
}

export function BicycleIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={256}
      height={256}
      viewBox="0 0 256 256"
      {...props}
    >
      <path
        fill="currentColor"
        d="M208 112a47.8 47.8 0 0 0-16.93 3.09L165.93 72H192a8 8 0 0 1 8 8a8 8 0 0 0 16 0a24 24 0 0 0-24-24h-40a8 8 0 0 0-6.91 12l11.65 20H99.26L82.91 60A8 8 0 0 0 76 56H48a8 8 0 0 0 0 16h23.41l13.71 23.51l-15.71 21.55a48.13 48.13 0 1 0 12.92 9.44l11.59-15.9l31.17 53.4a8 8 0 1 0 13.82-8l-30.32-52h57.48l11.19 19.17A48 48 0 1 0 208 112M80 160a32 32 0 1 1-20.21-29.74l-18.25 25a8 8 0 1 0 12.92 9.42l18.25-25A31.88 31.88 0 0 1 80 160m128 32a32 32 0 0 1-22.51-54.72l15.6 26.72a8 8 0 1 0 13.82-8l-15.61-26.79A32 32 0 1 1 208 192"
      ></path>
    </svg>
  );
}

export function BusIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="none" stroke="currentColor" strokeWidth={1.5}>
        <path d="M4 10c0-3.771 0-5.657 1.172-6.828S8.229 2 12 2s5.657 0 6.828 1.172S20 6.229 20 10v2c0 3.771 0 5.657-1.172 6.828S15.771 20 12 20s-5.657 0-6.828-1.172S4 15.771 4 12z"></path>
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M4 13h16m-4.5 3H17M7 16h1.5M6 19.5V21a1 1 0 0 0 1 1h1.5a1 1 0 0 0 1-1v-1m8.5-.5V21a1 1 0 0 1-1 1h-1.5a1 1 0 0 1-1-1v-1M20 9h1a1 1 0 0 1 1 1v1a1 1 0 0 1-.4.8L20 13M4 9H3a1 1 0 0 0-1 1v1a1 1 0 0 0 .4.8L4 13"
        ></path>
        <path strokeLinecap="round" d="M19.5 5h-15"></path>
      </g>
    </svg>
  );
}

export function CarIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={36}
      height={36}
      viewBox="0 0 36 36"
      {...props}
    >
      <path
        fill="currentColor"
        d="M15 17h3v2h-3z"
        className="clr-i-outline clr-i-outline-path-1"
      ></path>
      <path
        fill="currentColor"
        d="M26.45 14.17A22.1 22.1 0 0 0 19.38 7a9.64 9.64 0 0 0-9-.7a8.6 8.6 0 0 0-4.82 6.4c-.08.47-.14.92-.2 1.36A4 4 0 0 0 2 18v6.13a2 2 0 0 0 2 2V18a2 2 0 0 1 2-2h18.73A7.28 7.28 0 0 1 32 23.27V24h-2a4.53 4.53 0 1 0 .33 2H32a2 2 0 0 0 2-2v-.73a9.28 9.28 0 0 0-7.55-9.1M11 14H6.93c0-.31.09-.63.15-1A6.52 6.52 0 0 1 11 8Zm2 0V7.58a8.17 8.17 0 0 1 5.36 1.16A19 19 0 0 1 23.9 14Zm12.8 14.38a2.5 2.5 0 1 1 2.5-2.5a2.5 2.5 0 0 1-2.5 2.5"
        className="clr-i-outline clr-i-outline-path-2"
      ></path>
      <path
        fill="currentColor"
        d="M14.17 24a4.53 4.53 0 1 0 .33 2h5.3v-.25A6 6 0 0 1 20 24ZM10 28.38a2.5 2.5 0 1 1 2.5-2.5a2.5 2.5 0 0 1-2.5 2.5"
        className="clr-i-outline clr-i-outline-path-3"
      ></path>
      <path fill="none" d="M0 0h36v36H0z"></path>
    </svg>
  );
}

export function SpainIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={512}
      height={512}
      viewBox="0 0 512 512"
      {...props}
    >
      <path
        fill="currentColor"
        d="M105.596 82.69c-35.235-.159-63.219 3.749-79.754 13.432l22.717 61.192l72.273 16c-30.57 62.613-31.05 127.647-40.273 192l64 64c42.477-17.102 81.95-22.872 128-23.945c10.226-18.087 18.296-38.667 46.611-44.924c-1.617-30.208 15.277-36.212 32.686-52.81c-4.287-31.292-.694-49.583 20.918-88.274l84.212-58.535l-9.13-25.68c-114.8-25.068-252.215-52.047-342.26-52.455zm369.408 156.983c-9.503-.388-11.76 5.157-10.271 11.533c6.318 5.616 13.497 7.355 21.136 7.024c1.07-6.96-.788-13.039-6.338-18.012c-1.663-.312-3.17-.49-4.527-.545m-33.486 11.07c-9.603.076-18.698 8.776-27.637 20.13c2.107 1.053 28.094 18.962 28.094 18.962l15.1-31.254c-5.36-5.55-10.528-7.877-15.557-7.837zm-47.625 38.303c-8.017.003-15.008 1.902-15.83 15.188l10.183 7.373l9.131-22.475a83 83 0 0 0-3.484-.086"
      ></path>
    </svg>
  );
}

export function RadioIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="currentColor">
        <path d="M12 16a4 4 0 1 0 0-8a4 4 0 0 0 0 8"></path>
        <path
          fillRule="evenodd"
          d="M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12S6.477 2 12 2s10 4.477 10 10m-2 0a8 8 0 1 1-16 0a8 8 0 0 1 16 0"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
}

export function SelectionIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M20 9a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1h-4a1 1 0 0 0-1 1v1H9V4a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h1v6H4a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1v-1h6v1a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1h-1V9zm-3-4h2v2h-2zM5 5h2v2H5zm2 14H5v-2h2zm12 0h-2v-2h2zm-2-4h-1a1 1 0 0 0-1 1v1H9v-1a1 1 0 0 0-1-1H7V9h1a1 1 0 0 0 1-1V7h6v1a1 1 0 0 0 1 1h1z"
      ></path>
    </svg>
  );
}

export function NextIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      {...props}
      width={15}
      height={15}
      style={{ opacity: 0.5 }}
    >
      <path
        fill="currentColor"
        d="M7 1L5.6 2.5L13 10l-7.4 7.5L7 19l9-9z"
      ></path>
    </svg>
  );
}

export function DownIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={15}
      height={15}
      viewBox="0 0 15 15"
      {...props}
    >
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="square"
        d="m14 5l-6.5 7L1 5"
        strokeWidth={1}
      ></path>
    </svg>
  );
}
export function UpIcon(props) {
  return <DownIcon {...props} style={{ transform: "rotate(180deg)" }} />;
}

export function InfoIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      style={{ opacity: 0.5 }}
      {...props}
    >
      <g fill="currentColor">
        <path d="M11 10.98a1 1 0 1 1 2 0v6a1 1 0 1 1-2 0zm1-4.929a1 1 0 1 0 0 2a1 1 0 0 0 0-2"></path>
        <path
          fillRule="evenodd"
          d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10s10-4.477 10-10S17.523 2 12 2M4 12a8 8 0 1 0 16 0a8 8 0 0 0-16 0"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
}
export function TrashIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M4 7h16m-10 4v6m4-6v6M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2l1-12M9 7V4a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v3"
      ></path>
    </svg>
  );
}

export function SuccessIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        fill="currentColor"
        d="M11.4 6.85a.5.5 0 0 0-.707-.707l-3.65 3.65l-1.65-1.65a.5.5 0 0 0-.707.707l2 2a.5.5 0 0 0 .707 0l4-4z"
      ></path>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M8 0C3.58 0 0 3.58 0 8s3.58 8 8 8s8-3.58 8-8s-3.58-8-8-8M1 8c0-3.87 3.13-7 7-7s7 3.13 7 7s-3.13 7-7 7s-7-3.13-7-7"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}
