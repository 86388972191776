import React, {
  Fragment,
  useEffect,
  useRef,
  useState,
  useContext,
} from "react";
import mapboxgl from "mapbox-gl";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import DrawCircle from "mapbox-gl-draw-circle-mode";
import { Icon } from "components/Icon";
import { FormProvider, FormContext } from "./formContext";
import { STYLES_CONSTANTS } from "styles/constants/constants";
import {
  BicycleIcon,
  SpainIcon,
  WalkingIcon,
  BusIcon,
  CarIcon,
  RadioIcon,
  SelectionIcon,
  NextIcon,
  TrashIcon,
  SuccessIcon,
} from "./icons";
import {
  PageHeader,
  SearchBox,
  ContextMenu,
  DrawingInstructions,
  TimeSliderDialog,
  DataDrawer,
  Overlay,
  ModalInfo,
  TrashButton,
  SuccessModal,
} from "./styled-components";
import { useCreateKpiRequest, useGetMyProfile, useKpiData } from "queries/kpi";
import { toast } from "react-toastify";
import { KpiGroup } from "./components/filters";
import { Spinner } from "components/Spinner";
const OptionMap = {
  walking: "andando",
  bicycle: "en bici",
  public: "en transporte público",
  car: "en coche",
  admin: null,
};
mapboxgl.accessToken =
  "pk.eyJ1IjoiY2JyZWRlc2lnbiIsImEiOiIyZDY3OGRmYzk3NDQ5MWMwMDE5YWQ0Y2UxOWE5MWM3MCJ9.9rRnrrli22cqtEJDBKTrxQ";

function KpiMap() {
  const { data: kpiData } = useKpiData();
  const MapRef = useRef(null);
  const searchBoxRef = useRef(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [drawingType, setDrawingType] = useState(null);
  const [contextMenu, setContextMenu] = useState(null);
  const [clickedLocation, setClickedLocation] = useState(null);
  const initialized = useRef(false);
  const { mutateAsync: createKpiRequest, isLoading: isCreatingKpiRequest } =
    useCreateKpiRequest();
  const [showTimeSlider, setShowTimeSlider] = useState(false);
  const [selectedTime, setSelectedTime] = useState(10);
  const [selectedAreaType, setSelectedAreaType] = useState(null);
  const [showDataDrawer, setShowDataDrawer] = useState(false);
  const [showModalInfo, setShowModalInfo] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showMultipleRequestModal, setShowMultipleRequestModal] =
    useState(false);
  const [showAdminDialog, setShowAdminDialog] = useState(false);
  const [selectedAdminType, setSelectedAdminType] = useState(null);
  useEffect(() => {
    document.title = "Data Market - CBRE Zone";
  }, [myProfile]);
  const { data: myProfile } = useGetMyProfile();
  const { formData, setFormData } = useContext(FormContext);

  const hasValidSelections = () => {
    if (
      !formData.selectedGroups ||
      Object.keys(formData.selectedGroups).length === 0
    ) {
      return false;
    }

    let hasSelectedMetric = false;
    let hasErrors = false;

    Object.entries(formData.selectedGroups).forEach(([group, metrics]) => {
      Object.entries(metrics).forEach(([metric, data]) => {
        if (data.selected) {
          hasSelectedMetric = true;

          const metricConfig = kpiData["kpi-groups"][group][metric];

          if (metricConfig.filters) {
            if (!data.filters) {
              hasErrors = true;
              return;
            }

            Object.entries(metricConfig.filters).forEach(
              ([filterName, filterConfig]) => {
                const filterValue = data.filters[filterName];

                if (
                  filterConfig.selection === "MULTI" ||
                  filterConfig.data_type === "RANGE"
                ) {
                  return;
                }

                if (
                  filterValue === undefined ||
                  filterValue === null ||
                  filterValue === ""
                ) {
                  hasErrors = true;
                }
              }
            );
          }
        }
      });
    });

    return hasSelectedMetric && !hasErrors;
  };

  const handleContextMenu = (e) => {
    e.preventDefault();

    const draw = MapRef.current.draw;
    const currentMode = draw.getMode();
    if (
      isDrawing ||
      currentMode === "draw_circle" ||
      currentMode === "draw_polygon"
    ) {
      return;
    }

    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    const menuWidth = 200;
    const menuHeight = 450;

    const offsetX = 10;
    const offsetY = -10;

    let x = e.point.x + offsetX;
    let y = e.point.y + offsetY;

    if (x + menuWidth > viewportWidth) {
      x = e.point.x - menuWidth - offsetX;
    }

    if (y + menuHeight > viewportHeight) {
      y = viewportHeight - menuHeight - 10;
    }

    setClickedLocation([e.lngLat.lng, e.lngLat.lat]);
    setContextMenu({
      x,
      y,
    });
  };

  const handleAreaSelection = (type) => {
    const draw = MapRef.current.draw;

    draw.deleteAll();
    if (MapRef.current.marker) {
      MapRef.current.marker.remove();
      MapRef.current.marker = null;
    }

    switch (type) {
      case "radio":
        draw.changeMode("draw_circle");
        setIsDrawing(true);
        setDrawingType("radio");
        setSelectedAreaType("radius");
        setContextMenu(null);
        break;
      case "draw":
        draw.changeMode("draw_polygon");
        setIsDrawing(true);
        setDrawingType("draw");
        setSelectedAreaType("custom_shape");
        setContextMenu(null);
        break;
      case "walking":
      case "bicycle":
      case "public":
      case "car":
        setSelectedAreaType(type);
        setShowTimeSlider(contextMenu);

        MapRef.current.marker = new mapboxgl.Marker({
          color: STYLES_CONSTANTS.COLORS.ACCENT_GREEN,
          draggable: true,
        })
          .setLngLat(clickedLocation)
          .addTo(MapRef.current);

        setContextMenu(null);
        break;
      case "admin":
        setSelectedAreaType(type);
        setShowAdminDialog(contextMenu);

        MapRef.current.marker = new mapboxgl.Marker({
          color: STYLES_CONSTANTS.COLORS.ACCENT_GREEN,
          draggable: true,
        })
          .setLngLat(clickedLocation)
          .addTo(MapRef.current);

        setContextMenu(null);
        break;
      default:
        MapRef.current.marker = new mapboxgl.Marker({
          color: STYLES_CONSTANTS.COLORS.ACCENT_GREEN,
          draggable: true,
        })
          .setLngLat(clickedLocation)
          .addTo(MapRef.current);
        setContextMenu(null);
    }
  };

  const handleCancelDrawing = () => {
    const draw = MapRef.current.draw;
    draw.deleteAll();
    if (MapRef.current.marker) {
      MapRef.current.marker.remove();
      MapRef.current.marker = null;
    }
    draw.changeMode("simple_select");
    setIsDrawing(false);
    setDrawingType(null);
    setShowModalInfo(false);
  };

  const handleConfirmDrawing = () => {
    const draw = MapRef.current.draw;
    const features = draw.getAll();
    if (features.features.length > 0) {
      setShowDataDrawer(true);
    }
    setIsDrawing(false);
    setDrawingType(null);
  };

  const handleTrashDrawing = () => {
    const draw = MapRef.current.draw;
    draw.deleteAll();
    if (drawingType === "radio") {
      draw.changeMode("draw_circle");
    } else if (drawingType === "draw") {
      draw.changeMode("draw_polygon");
    }
  };

  const handleTimeConfirm = () => {
    setShowTimeSlider(false);
    setShowDataDrawer(true);
  };

  const handleClearForm = () => {
    setFormData({
      selectedGroups: {},
      timeValue: null,
    });

    setShowDataDrawer(false);
    setContextMenu(null);
    handleCancelDrawing();
  };

  const handleAdminTypeSelect = () => {
    setShowAdminDialog(false);
    setShowDataDrawer(true);
  };

  const geojsonToWkt = (geojson) => {
    if (!geojson) return null;

    if (geojson.lng && geojson.lat) {
      return `POINT(${geojson.lng} ${geojson.lat})`;
    }

    if (geojson.features && geojson.features.length > 0) {
      const geometry = geojson.features[0].geometry;

      if (geometry.type === "Polygon") {
        const coordinates = geometry.coordinates[0]
          .map((coord) => `${coord[0]} ${coord[1]}`)
          .join(",");
        return `POLYGON((${coordinates}))`;
      }

      if (geometry.type === "Circle") {
        const center = geometry.coordinates;
        return `POINT(${center[0]} ${center[1]})`;
      }
    }

    return null;
  };

  const handleSubmit = async () => {
    if (!hasValidSelections()) {
      toast.info("Por favor, selecciona al menos una opción antes de enviar");
      return;
    }

    const draw = MapRef.current.draw;
    const features = draw.getAll();

    let hasErrors = false;
    const errors = [];

    Object.entries(formData.selectedGroups).forEach(([group, metrics]) => {
      Object.entries(metrics).forEach(([metric, data]) => {
        if (data.selected) {
          const metricConfig = kpiData["kpi-groups"][group][metric];

          if (metricConfig.filters) {
            if (!data.filters) {
              hasErrors = true;
              errors.push(`${group} > ${metric}: Rellena los filtros`);
              return;
            }

            Object.entries(metricConfig.filters).forEach(
              ([filterName, filterConfig]) => {
                const filterValue = data.filters[filterName];

                if (filterConfig.selection === "MULTI") {
                  return;
                }

                if (
                  filterValue === undefined ||
                  filterValue === null ||
                  filterValue === ""
                ) {
                  hasErrors = true;
                  errors.push(
                    `${group} > ${metric} > ${filterName} es requerido.`
                  );
                }
              }
            );
          }
        }
      });
    });

    if (hasErrors) {
      toast.info(
        `Por favor, rellena todos los campos requeridos:\n${errors.join("\n")}`
      );
      return;
    }

    const markerPosition = MapRef.current.marker
      ? MapRef.current.marker.getLngLat()
      : null;

    let selectionType;
    if (selectedAreaType) {
      selectionType = selectedAreaType;
    } else if (features.features.length > 0) {
      selectionType = drawingType === "radio" ? "radius" : "custom shape";
    } else {
      selectionType = null;
    }

    const geometry = features.features.length > 0 ? features : markerPosition;
    const wktGeometry = geojsonToWkt(geometry);

    const selectionData = {
      type: selectionType,
      geometry: wktGeometry,
      timeValue: selectedTime,
    };

    const submitData = {
      selection: selectionData,
      formData: formData.selectedGroups,
    };
    const type = ["walking", "bicycle", "public", "car"].includes(selectionType)
      ? "isochrone"
      : selectionType == "admin"
      ? "admin_area"
      : selectionType;

    const subtype =
      type == "admin_area"
        ? selectedAdminType
        : type == "isochrone"
        ? selectionType
        : null;
    const value = type == "isochrone" ? selectedTime : null;

    console.log({
      email: myProfile.email[0].email,
      datetime: new Date().toISOString(),
      "influence-area": {
        type,
        subtype,
        value,
        geometry: wktGeometry,
      },
      "kpi-groups": submitData.formData,
    });
    const response = await createKpiRequest({
      email: myProfile.email[0].email,
      datetime: new Date().toISOString(),
      "influence-area": {
        type,
        subtype,
        value,
        geometry: wktGeometry,
      },
      "kpi-groups": submitData.formData,
    });

    setShowSuccessModal(true);
    handleClearForm();
  };

  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false);
  };

  const handleCloseMultipleRequestModal = () => {
    setShowMultipleRequestModal(false);
  };

  useEffect(() => {
    if (initialized.current) return;
    initialized.current = true;

    MapRef.current = new mapboxgl.Map({
      container: "map",
      style: "mapbox://styles/mapbox/dark-v11",
      center: [-3.7366, 40.4208],
      zoom: 9,
      attributionControl: false,
      navigationControl: false,
      dragRotate: false,
      touchZoomRotate: false,
    });

    const initSearch = () => {
      if (!searchBoxRef.current && window.mapboxsearch) {
        const searchBox = new window.mapboxsearch.MapboxGeocoder();

        searchBox.accessToken = mapboxgl.accessToken;
        searchBox.placeholder =
          "Buscar una dirección/Municipio, o haz click derecho en el mapa";
        searchBox.options = {
          language: "es",
          countries: ["es"],
          bbox: [-9.38, 36.05, 3.35, 43.75],
        };
        searchBox.addEventListener("retrieve", (event) => {
          const coordinates = event.detail.geometry.coordinates;

          if (MapRef.current.marker) {
            MapRef.current.marker.remove();
          }

          MapRef.current.marker = new mapboxgl.Marker({
            color: STYLES_CONSTANTS.COLORS.ACCENT_GREEN,
            draggable: true,
          })
            .setLngLat(coordinates)
            .addTo(MapRef.current);

          const point = MapRef.current.project(coordinates);

          setClickedLocation(coordinates);
          setContextMenu({
            x: point.x,
            y: point.y,
          });
        });
        searchBox.addEventListener("clear", () => {
          if (MapRef.current.marker) {
            MapRef.current.marker.remove();
            MapRef.current.marker = null;
            setContextMenu(null);
          }
        });
        const searchContainer = document.getElementById("search-box-container");
        if (searchContainer) {
          searchBoxRef.current = searchBox;
          searchContainer.appendChild(searchBox.onAdd(MapRef.current));
        }
      }
    };

    initSearch();

    window.mapboxSearchCallback = initSearch;

    const searchCheckInterval = setInterval(() => {
      if (window.mapboxsearch && !searchBoxRef.current) {
        initSearch();
        clearInterval(searchCheckInterval);
      }
    }, 100);

    const modes = MapboxDraw.modes;
    modes.draw_circle = DrawCircle;
    var Draw = new MapboxDraw({
      displayControlsDefault: false,
      userProperties: true,
      modes: {
        ...MapboxDraw.modes,
        draw_circle: DrawCircle,
      },
      styles: [
        {
          id: "gl-draw-polygon-fill",
          type: "fill",
          filter: ["all", ["==", "$type", "Polygon"], ["!=", "mode", "static"]],
          paint: {
            "fill-color": STYLES_CONSTANTS.COLORS.ACCENT_GREEN,
            "fill-outline-color": STYLES_CONSTANTS.COLORS.ACCENT_GREEN,
            "fill-opacity": 0.3,
          },
        },
        {
          id: "gl-draw-polygon-stroke-active",
          type: "line",
          filter: ["all", ["==", "$type", "Polygon"], ["!=", "mode", "static"]],
          paint: {
            "line-color": STYLES_CONSTANTS.COLORS.ACCENT_GREEN,
            "line-width": 2,
          },
        },
        {
          id: "gl-draw-polygon-and-line-vertex-active",
          type: "circle",
          filter: ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"]],
          paint: {
            "circle-radius": 5,
            "circle-color": STYLES_CONSTANTS.COLORS.ACCENT_GREEN,
          },
        },
      ],
    });
    MapRef.current.addControl(Draw, "top-left");
    MapRef.current.draw = Draw;

    MapRef.current.on("contextmenu", handleContextMenu);
    MapRef.current.on("click", () => {
      setContextMenu(null);
    });

    const handleEscKey = (e) => {
      if (e.key === "Escape" && isDrawing) {
        handleCancelDrawing();
      }
    };
    document.addEventListener("keydown", handleEscKey);

    return () => {
      clearInterval(searchCheckInterval);
      document.removeEventListener("keydown", handleEscKey);
      if (MapRef.current) {
        MapRef.current.remove();
      }
      if (searchBoxRef.current) {
        searchBoxRef.current.onRemove();
      }
    };
  }, []);
  useEffect(() => {
    if (isDrawing) {
      MapRef.current.on("contextmenu", () => {});
    } else {
      MapRef.current.on("contextmenu", handleContextMenu);
    }
  }, [isDrawing]);
  return (
    <Fragment>
      <PageHeader>
        <div className="logo-container">
          <Icon path="cbre-logo" style={{ paddingTop: 7 }} />
          <span className="divider" />
          <h1>Data Market</h1>
        </div>
        <div
          className="help-container"
          onClick={() => setShowMultipleRequestModal(true)}
        >
          <h2>¿Quieres hacer una petición multiple?</h2>
        </div>
      </PageHeader>
      <SearchBox id="search-box-container"></SearchBox>

      <div id="map" style={{ width: "100%", height: "100%" }}></div>

      {contextMenu && (
        <ContextMenu
          style={{
            left: `${contextMenu.x}px`,
            top: `${contextMenu.y}px`,
          }}
        >
          <h3>Seleccione tipo área de influencia:</h3>
          <div
            className="menu-item"
            onClick={() => handleAreaSelection("walking")}
          >
            <div>
              <WalkingIcon width={20} height={20} /> Andando
            </div>
            <NextIcon width={20} height={20} />
          </div>
          <div
            className="menu-item"
            onClick={() => handleAreaSelection("bicycle")}
          >
            <div>
              <BicycleIcon width={20} height={20} /> Bicicleta
            </div>
            <NextIcon width={20} height={20} />
          </div>
          <div
            className="menu-item"
            onClick={() => handleAreaSelection("public")}
          >
            <div>
              <BusIcon width={20} height={20} /> Transporte público
            </div>
            <NextIcon width={20} height={20} />
          </div>
          <div className="menu-item" onClick={() => handleAreaSelection("car")}>
            <div>
              <CarIcon width={20} height={20} /> Coche
            </div>
            <NextIcon width={20} height={20} />
          </div>
          <div
            className="menu-item"
            onClick={() => handleAreaSelection("admin")}
          >
            <div>
              <SpainIcon width={20} height={20} /> Área administrativa
            </div>
            <NextIcon width={20} height={20} />
          </div>
          <div
            className="menu-item"
            onClick={() => handleAreaSelection("radio")}
          >
            <div>
              <RadioIcon width={20} height={20} /> Radio
            </div>
          </div>
          <div
            className="menu-item"
            onClick={() => handleAreaSelection("draw")}
          >
            <div>
              <SelectionIcon width={20} height={20} /> Pintar el área
            </div>
          </div>
        </ContextMenu>
      )}

      {isDrawing && (
        <Fragment>
          <TrashButton onClick={handleTrashDrawing}>
            <TrashIcon width={24} height={24} />
          </TrashButton>
          <DrawingInstructions>
            <h3>Dibuje en el mapa</h3>
            <p>Una vez finalice, haga click en "Confirmar"</p>
            <div className="buttons">
              <button className="cancel" onClick={handleCancelDrawing}>
                Atrás
              </button>
              <button className="confirm" onClick={handleConfirmDrawing}>
                Confirmar
              </button>
            </div>
          </DrawingInstructions>
        </Fragment>
      )}

      {showTimeSlider && (
        <TimeSliderDialog
          style={{
            left: `${showTimeSlider.x}px`,
            top: `${showTimeSlider.y}px`,
          }}
        >
          <h3>Indique cuántos minutos {OptionMap[selectedAreaType]}:</h3>
          <div className="slider-container">
            <input
              type="range"
              min="0"
              max="60"
              step="5"
              value={selectedTime}
              onChange={(e) => setSelectedTime(e.target.value)}
            />
            <div className="time-display">{selectedTime} min</div>
          </div>
          <div className="buttons">
            <button
              className="cancel"
              onClick={() => {
                setShowTimeSlider(null);
                setContextMenu(null);
                setShowDataDrawer(false);
                handleCancelDrawing();
              }}
            >
              Atrás
            </button>
            <button className="confirm" onClick={handleTimeConfirm}>
              Confirmar
            </button>
          </div>
        </TimeSliderDialog>
      )}

      {showAdminDialog && (
        <TimeSliderDialog
          style={{
            left: `${showAdminDialog.x}px`,
            top: `${showAdminDialog.y}px`,
          }}
        >
          <h3>Seleccione el tipo de administrativa:</h3>
          <div className="admin-options">
            {[
              "Comunidad autónoma",
              "Provincia",
              "Municipio",
              "Distrito",
              "Sección censal",
              "Código postal",
            ].map((type) => (
              <label key={type}>
                <input
                  type="radio"
                  name="adminType"
                  value={type}
                  checked={selectedAdminType === type}
                  onChange={(e) => setSelectedAdminType(e.target.value)}
                />
                {type}
              </label>
            ))}
          </div>
          <div className="buttons">
            <button
              className="cancel"
              onClick={() => {
                setShowAdminDialog(false);
                setContextMenu(null);
                handleCancelDrawing();
              }}
            >
              Atrás
            </button>
            <button
              className="confirm"
              onClick={handleAdminTypeSelect}
              disabled={!selectedAdminType}
            >
              Confirmar
            </button>
          </div>
        </TimeSliderDialog>
      )}

      <Overlay isOpen={showDataDrawer} />
      <ModalInfo isOpen={showModalInfo}>
        <button
          className="close-button"
          onClick={() => setShowModalInfo(false)}
        >
          ✕
        </button>
        <h3>{showModalInfo && showModalInfo.title}</h3>

        {showModalInfo &&
          Object.entries(showModalInfo.info).map(([key, value]) => (
            <div key={key}>
              <strong>{key}:</strong> {value}
            </div>
          ))}
      </ModalInfo>
      {showDataDrawer && (
        <DataDrawer isOpen={showDataDrawer}>
          <Spinner isLoading={isCreatingKpiRequest} isOverlay>
            <div className="drawer-header">
              <h2>Seleccione qué datos se quiere descargar:</h2>
              <button className="close-button" onClick={handleClearForm}>
                ✕
              </button>
            </div>
            <div className="drawer-content">
              {kpiData &&
                Object.entries(kpiData["kpi-groups"]).map(
                  ([group, metrics]) => (
                    <KpiGroup
                      key={group}
                      group={group}
                      metrics={metrics}
                      setShowModalInfo={setShowModalInfo}
                    />
                  )
                )}
            </div>
            <div className="drawer-footer">
              <button className="cancel" onClick={handleClearForm}>
                Atrás
              </button>
              <button
                className="confirm"
                onClick={handleSubmit}
                disabled={!hasValidSelections() || isCreatingKpiRequest}
                style={{
                  opacity: hasValidSelections() ? 1 : 0.5,
                  cursor: hasValidSelections() ? "pointer" : "not-allowed",
                }}
              >
                {isCreatingKpiRequest ? "Enviando..." : "Confirmar"}
              </button>
            </div>
          </Spinner>
        </DataDrawer>
      )}

      {showSuccessModal && (
        <Fragment>
          <Overlay isOpen={true} onClick={handleCloseSuccessModal} />
          <SuccessModal>
            <button className="close-button" onClick={handleCloseSuccessModal}>
              ×
            </button>
            <div className="success-icon">
              <SuccessIcon width={52} height={52} color="#00e676" />
            </div>
            <h2>Petición enviada</h2>
            <p>
              En las próximas horas te llegará un mail con las instrucciones
              para descargar el informe y geometrías generados.
            </p>
          </SuccessModal>
        </Fragment>
      )}
      {showMultipleRequestModal && (
        <Fragment>
          <Overlay isOpen={true} onClick={handleCloseMultipleRequestModal} />
          <SuccessModal>
            <button
              className="close-button"
              onClick={handleCloseMultipleRequestModal}
            >
              ×
            </button>

            <h2>¿Quieres hacer una petición multiple? </h2>
            <p>
              Descarga este template, complétalo siguiendo las indicaciones y
              envíalo al buzón de{" "}
              <a href="mailto:spain.digital@cbre.com">spain.digital@cbre.com</a>
            </p>
          </SuccessModal>
        </Fragment>
      )}
    </Fragment>
  );
}
export default function KpiMapView() {
  return (
    <FormProvider>
      <KpiMap />
    </FormProvider>
  );
}
